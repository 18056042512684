const AtrractionPolaroidCard = ({ src, placeName, isSelected, onClick }) => {
  return (
    <button
      onClick={onClick} // 부모 컴포넌트에서 전달된 클릭 핸들러
      className={`
        flex flex-col
        justify-center items-center 
        w-[150px] h-[150px]
        sm:w-[160px] md:w-[250px] lg:w-[300px]
        sm:h-[160px] md:h-[250px] lg:h-[300px]
        bg-white
        cursor-pointer
        ${
          isSelected ? "border-4 border-blue-500" : "border border-transparent"
        } 
      `}
    >
      <img
        className={`w-[130px] h-[110px]
        sm:w-[140px] md:w-[220px] lg:w-[270px]
        sm:h-[120px] md:h-[200px] lg:h-[240px]
        bg-slate-300
        aspect-square
        
        `}
        src={src}
        alt="Attraction"
      />
      <p className="text-black font-bold text-sm pt-1">{placeName}</p>
    </button>
  );
};

export default AtrractionPolaroidCard;
