import axios from "axios";
import { useNavigate } from "react-router-dom";
import MainButton from "./MainButton";
import { ContentWrapper, MainContainer } from "../BackGround.styles";
import { useEffect } from "react";
import API from "../../API";
import { getUserInfo } from "apis/userApi";
import { useDispatch, useSelector } from "react-redux";
import { setUserInfo } from "stores/userSlice";
import LomHeader from "components/LomHeader";

export default function MainPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchInfo = async () => {
      try {
        const response = await getUserInfo();
        dispatch(setUserInfo(response.data));
      } catch (error) {
        console.log(`유저 정보 저장 실패${error}`);
      }
    };
    fetchInfo();
  }, []);

  return (
    <MainContainer>
      <ContentWrapper>
        <LomHeader />
        <section className="flex flex-col gap-[10px] mb-10">
          <MainButton onClick={() => navigate("/create-select-place")}>
            나만의 자물쇠 만들기
          </MainButton>
          <MainButton onClick={() => navigate("/attraction")}>
            명소 둘러보기
          </MainButton>
          <MainButton onClick={() => navigate("/my-page")}>
            나의 자물쇠 보기
          </MainButton>
        </section>
      </ContentWrapper>
    </MainContainer>
  );
}
