import React, { useRef, useState, useEffect } from "react";
import { useSpring, animated } from "@react-spring/web";
import { useDrag } from "react-use-gesture";
import { mainLockPreview } from "apis/lockApi";

const LockSlider = ({ data, fetchMoreData, hasMore, setPreviewData }) => {
  const itemWidth = 100; // 각 아이템의 폭
  const itemHeight = 100; // 각 아이템의 높이
  const itemSpacing = 20; // 아이템 간 간격
  const itemsPerRow = 3; // 한 줄에 표시할 아이템 수
  const itemsPerPage = 6; // 한 페이지에 표시할 아이템 수
  const containerWidth = itemsPerRow * (itemWidth + itemSpacing) - itemSpacing;

  const sliderRef = useRef(null);
  const [{ x }, api] = useSpring(() => ({
    x: 0,
    config: { tension: 9999, friction: 1000 }, // tension(속도), friction(감쇠)
  }));

  const [currentPage, setCurrentPage] = useState(1); // 현재 페이지
  const [totalPages, setTotalPages] = useState(8); // 총 페이지 수

  useEffect(() => {
    if (data) {
      setTotalPages(Math.ceil(data.length / itemsPerPage));
    }
  }, [data]);

  const firstRowItems = (data || []).filter(
    (_, index) => Math.floor(index / itemsPerRow) % 2 === 0
  );
  const secondRowItems = (data || []).filter(
    (_, index) => Math.floor(index / itemsPerRow) % 2 === 1
  );

  const maxTranslate = 0; // 왼쪽 끝 (첫 페이지)
  const minTranslate = -containerWidth * (totalPages - 1); // 오른쪽 끝 (마지막 페이지)


  const bind = useDrag(
    ({ down, movement: [mx], cancel }) => {
      const nextX = x.get() + mx * 0.3;
  
      // 드래그가 끝났을 때 로직
      if (!down) {
        const newPage = Math.round(-nextX / containerWidth) + 1;
  
        // 마지막 페이지에서 드래그 추가 요청
        if (newPage >= totalPages && hasMore) {
          fetchMoreData();
        }
  
        // 페이지 계산 후 애니메이션 이동
        setCurrentPage(Math.max(1, Math.min(newPage, totalPages)));
        api.start({ x: -((newPage - 1) * containerWidth) });
        return;
      }
  
      // 드래그 중 로직
      if (nextX > maxTranslate) {
        api.start({ x: maxTranslate, immediate: true });
        return;
      }
      if (nextX < minTranslate) {
        // 마지막 페이지 이후 추가로 드래그할 경우 API 호출
        if (hasMore) {
          fetchMoreData();
        }
  
        api.start({ x: minTranslate, immediate: true });
        return;
      }
  
      // 드래그 이동 반영
      api.start({ x: nextX, immediate: true });
    },
    { axis: "x", filterTaps: true, threshold: 60 }
  );
  
  


  const goToPage = (page) => {
    const xOffset = -((page - 1) * containerWidth);
    api.start({ x: xOffset });
    setCurrentPage(page);

    if (page >= totalPages && hasMore) {
      fetchMoreData();
    }
  };

  const getImagePath = (imageName) => {
    try {
      return require(`../../assets/${imageName}`);
    } catch {
      return null; 
    }
  };

  const truncateText = (text, maxLength) => {
    if (!text) return "";
    return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
  };

  if (!data || data.length === 0) {
    return <div>Loading...</div>;
  }

  const handlePreview = async (mainLockId, imgPath) => {
    try {
      const response = await mainLockPreview(mainLockId);
      console.log(response.data)
      setPreviewData({
        ...response.data,
        imgPath: imgPath
      })
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <div
        className="slider-container"
        style={{
          width: `${containerWidth}px`,
          height: `${itemHeight * 2 + itemSpacing}px`,
          overflow: "hidden",
          touchAction: "pan-y",
          margin: "0 auto",
        }}
        ref={sliderRef}
      >
        <animated.div
          {...bind()}
          style={{
            display: "flex",
            flexDirection: "row",
            x,
          }}
        >
          {Array.from({ length: totalPages }).map((_, pageIndex) => (
            <div
              key={`page-${pageIndex}`}
              style={{
                display: "flex",
                flexDirection: "column",
                width: `${containerWidth}px`,
                flexShrink: 0,
              }}
            >
              <div
                style={{
                  display: "flex",
                  marginBottom: `${itemSpacing}px`,
                }}
              >
                {firstRowItems
                  .slice(pageIndex * itemsPerRow, (pageIndex + 1) * itemsPerRow)
                  .map((item, index) => (
                    <div
                      key={item.mainLockId || `first-${pageIndex}-${index}`}
                      className="flex flex-col items-center justify-center rounded-md"
                      style={{
                        width: `${itemWidth}px`,
                        height: `${itemHeight}px`,
                        marginRight: `${itemSpacing}px`,
                        position: "relative", // 닉네임 겹치기용
                      }}
                    >
                      {item.mainLockImage ? (
                        <div onClick={() =>
                          handlePreview(item.mainLockId, getImagePath(item.mainLockImage))
                        }>
                          <img
                            src={getImagePath(item.mainLockImage)}
                            alt="lock"
                            style={{
                              width: "60px",
                              height: "60px",
                              objectFit: "contain",
                            }}
                          />
                          <p
                            style={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)", // 이미지 정중앙 정렬
                              color: "white", // 흰색 글자
                              fontWeight: "bold",
                              fontSize: "12px", // 적당한 크기
                              textShadow: "2px 2px 4px rgba(0, 0, 0, 2)",
                              padding: "2px 5px",
                              borderRadius: "4px",
                            }}
                          >
                            {item.ownerName}
                          </p>
                        </div>
                      ) : (
                        <div style={{ width: "60px", height: "60px" }} />
                      )}
                      <p className="text-sm" style={{ marginTop: "5px" }}>
                        {truncateText(item.message, 5)}
                      </p>
                    </div>
                  ))}
              </div>
              <div style={{ display: "flex" }}>
                {secondRowItems
                  .slice(pageIndex * itemsPerRow, (pageIndex + 1) * itemsPerRow)
                  .map((item, index) => (
                    <div
                      key={item.mainLockId || `second-${pageIndex}-${index}`}
                      className="flex flex-col items-center justify-center rounded-md"
                      style={{
                        width: `${itemWidth}px`,
                        height: `${itemHeight}px`,
                        marginRight: `${itemSpacing}px`,
                        position: "relative", // 닉네임 겹치기용
                      }}
                    >
                      {item.mainLockImage ? (
                        <div onClick={() =>
                          handlePreview(item.mainLockId, getImagePath(item.mainLockImage))
                        }>
                          <img
                            src={getImagePath(item.mainLockImage)}
                            alt="lock"
                            style={{
                              width: "60px",
                              height: "60px",
                              objectFit: "contain",
                            }}
                          />
                          <p
                            style={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)", // 이미지 정중앙 정렬
                              color: "white", // 흰색 글자
                              fontWeight: "bold",
                              fontSize: "12px", // 적당한 크기
                              textShadow: "2px 2px 4px rgba(0, 0, 0, 2)",
                              padding: "2px 5px",
                              borderRadius: "4px",
                            }}
                          >
                            {item.ownerName}
                          </p>
                        </div>
                      ) : (
                        <div style={{ width: "60px", height: "60px" }} />
                      )}
                      <p className="text-sm" style={{ marginTop: "5px" }}>
                        {truncateText(item.message, 5)}
                      </p>
                    </div>
                  ))}
              </div>
            </div>
          ))}

        </animated.div>
      </div>

      <nav
        className="pagination"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <button
          onClick={() => goToPage(Math.max(currentPage - 1, 1))}
          disabled={currentPage === 1}
          style={{ marginRight: "10px" }}
        >
          이전
        </button>
        <span>
          {currentPage} / {totalPages}
        </span>
        <button
          onClick={() => goToPage(Math.min(currentPage + 1, totalPages))}
          disabled={currentPage === totalPages && !hasMore}
          style={{ marginLeft: "10px" }}
        >
          다음
        </button>
      </nav>
    </>
  );
};

export default LockSlider;
