import React, { useEffect } from 'react';
import namsan from 'assets/attractions/attraction-1.png'

const KakaoShareButton = ({link}) => {
  console.log(link)
  useEffect(() => {
    // Kakao SDK 초기화
    const script = document.createElement('script');
    script.src = 'https://t1.kakaocdn.net/kakao_js_sdk/2.7.4/kakao.min.js';
    script.integrity = 'sha384-DKYJZ8NLiK8MN4/C5P2dtSmLQ4KwPaoqAfyA/DfmEc1VDxu4yyC7wy6K1Hs90nka';
    script.crossOrigin = 'anonymous';
    script.onload = () => {
      Kakao.init('c089c8172def97eb00c07217cae17495'); // JavaScript 키
    };
    document.body.appendChild(script);
    
    // Cleanup on component unmount
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const shareMessage = () => {
    Kakao.Share.sendDefault({
      objectType: 'feed',
      content: {
        title: '우리의 추억 자물쇠',
        description: '#ROM #남산타워 #추억 #자물쇠',
        imageUrl:
          namsan,
        link: {
          webUrl: link,
        },
      },
      social: {
        likeCount: 286,
        commentCount: 45,
        sharedCount: 845,
      },
      buttons: [
        // {
        //   title: '사이트로 가기',
        //   link: {
        //     mobileWebUrl: 'https://mydevlogme.site',
        //     webUrl: 'https://mydevlogme.site',
        //   },
        // },
        {
          title: '공유받은 자물쇠 보기',
          link: {
            mobileWebUrl: link,
            webUrl: link,
          },
        },
      ],
    });
  };

  return (
    <a className='w-[70px] h-[70px]' id="kakaotalk-sharing-btn" href="javascript:void(0)" onClick={shareMessage}>
      <img
        src="https://developers.kakao.com/assets/img/about/logos/kakaotalksharing/kakaotalk_sharing_btn_medium.png"
        alt="카카오톡 공유 보내기 버튼"
      />
    </a>
  );
};

export default KakaoShareButton;
