const LomHeader = ({title}) => {
  return (
    <header>
      <h1 className="font-bold text-center mt-20 text-2xl">
        {title || "우리의 추억 자물쇠"}
      </h1>
      <h3 className="text-center mt-4">Lock Our Memory</h3>
    </header>
  );
};

export default LomHeader;
