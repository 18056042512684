import React, { useState } from "react";
import { useSpring, animated } from "@react-spring/web";
import { useDrag } from "react-use-gesture";
import MessageModal from "./MessageModal";

const LockDetailSlider = ({ subLocks }) => {
  const rows = 2; // 두 줄
  const itemsPerRow = 4; // 한 줄에 4개
  const itemsPerPage = rows * itemsPerRow; // 한 페이지에 표시할 아이템 수
  const itemWidth = 80; // 각 아이템의 폭
  const itemSpacing = 10; // 아이템 간 간격
  const containerWidth = itemsPerRow * (itemWidth + itemSpacing) - itemSpacing;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedLock, setSelectedLock] = useState({})

  const [{ x }, api] = useSpring(() => ({
    x: 0,
    config: { tension: 9999, friction: 1000 }, // tension(속도), friction(감쇠)
  }));
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil((subLocks?.length || 0) / itemsPerPage);

  const bind = useDrag(
    ({ down, movement: [mx] }) => {
      const maxTranslate = 0;
      const minTranslate = -(totalPages - 1) * containerWidth;
      const nextX = x.get() + mx * 0.3;

      if (nextX > maxTranslate) {
        api.start({ x: maxTranslate, immediate: true });
        return;
      }

      if (nextX < minTranslate) {
        api.start({ x: minTranslate, immediate: true });
        return;
      }

      if (down) {
        api.start({ x: nextX, immediate: true });
      } else {
        const newPage = Math.round(-nextX / containerWidth) + 1;
        setCurrentPage(Math.max(1, Math.min(newPage, totalPages)));
        api.start({ x: -((newPage - 1) * containerWidth) });
      }
    },
    { axis: "x", filterTaps: true, threshold: 60 }
  );

  const goToPage = (page) => {
    if (page < 1 || page > totalPages) return;
    const xOffset = -((page - 1) * containerWidth);
    api.start({ x: xOffset });
    setCurrentPage(page);
  };

  const getImagePath = (imageName) => {
    try {
      return require(`../../assets/${imageName}`);
    } catch {
      return null;
    }
  };

  const textStyle = {
    textShadow: "2px 2px 4px rgba(0, 0, 0, 2)",
  };

  if (!subLocks || subLocks.length === 0) {
    return (
      <div
        className="flex justify-center items-center"
        style={{ height: "200px" }}
      >
        <p className="text-gray-500 text-lg font-medium">
          아직 답글 자물쇠가 없습니다.
        </p>
      </div>
    );
  }

  const handleSubClick = (item) => {
    setSelectedLock(item)
    openModal()
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <div
        className="slider-container"
        style={{
          width: `${containerWidth}px`,
          overflow: "hidden",
          touchAction: "pan-y",
          margin: "0 auto",
        }}
      >
        <animated.div
          {...bind()}
          style={{
            display: "flex",
            flexDirection: "row",
            x,
          }}
        >
          {Array.from({ length: totalPages }).map((_, pageIndex) => (
            <div
              key={`page-${pageIndex}`}
              style={{
                display: "flex",
                flexDirection: "column",
                width: `${containerWidth}px`,
                flexShrink: 0,
              }}
            >
              {Array.from({ length: rows }).map((_, rowIndex) => {
                const startIdx =
                  pageIndex * itemsPerPage + rowIndex * itemsPerRow;
                const endIdx = startIdx + itemsPerRow;

                const rowItems = subLocks.slice(startIdx, endIdx);
                const emptySlots = itemsPerRow - rowItems.length;

                return (
                  <div
                    key={`row-${rowIndex}`}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: `${itemSpacing}px`,
                      marginBottom: `${itemSpacing}px`,
                    }}
                  >
                    {rowItems.map((item, index) => (
                      <div
                        key={
                          item.subLockId ||
                          `item-${pageIndex}-${rowIndex}-${index}`
                        }
                        className="relative w-20 h-20 flex items-center justify-center"
                        onClick={() => handleSubClick(item)}
                      >
                        <img
                          src={getImagePath(item.subLockImage)}
                          alt="서브 자물쇠"
                          className="w-full h-full object-cover rounded"
                        />
                        <p
                          className="absolute inset-0 flex items-center justify-center text-white text-sm font-bold"
                          style={textStyle}
                        >
                          {item.ownerName}
                        </p>
                      </div>
                    ))}

                    {Array.from({ length: emptySlots }).map((_, emptyIndex) => (
                      <div
                        key={`empty-${pageIndex}-${rowIndex}-${emptyIndex}`}
                        className="w-20 h-20"
                      />
                    ))}
                  </div>
                );
              })}
            </div>
          ))}
        </animated.div>
      </div>

      <div
        className="pagination"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <button
          onClick={() => goToPage(currentPage - 1)}
          disabled={currentPage === 1}
          style={{ marginRight: "10px" }}
        >
          이전
        </button>
        <span>
          {currentPage} / {totalPages}
        </span>
        <button
          onClick={() => goToPage(currentPage + 1)}
          disabled={currentPage === totalPages}
          style={{ marginLeft: "10px" }}
        >
          다음
        </button>
      </div>

      {isModalOpen && <MessageModal onClose={closeModal} data={selectedLock} />}
    </div>
  );
};

export default LockDetailSlider;
