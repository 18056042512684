import { ContentWrapper, MainContainer } from "../BackGround.styles";
import AttractionButton from "../AttractionPage/AttractionButton";
import { useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import LockCard from "./LockCard";
import { useDispatch } from "react-redux";
import { useRef, useState } from "react";
import { setdesign } from "stores/mainLockSlice";
import testLockImage1 from "assets/locks/lock-1.png";
import testLockImage2 from "assets/locks/lock-2.png";
import testLockImage3 from "assets/locks/lock-3.png";
import testLockImage4 from "assets/locks/lock-4.png";

const SelectDesignPage = () => {
  const [selectedCardId, setSelectedCardId] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState("basic");
  const [isSelected, setIsSelected] = useState(true);
  const [previousDiff, setPreviousDiff] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // 카테고리와 자물쇠 매핑
  const categories = [
    { name: "기본", id: "basic" },
    { name: "하트", id: "heart" },
    { name: "편지", id: "letter" },
    { name: "선물", id: "gift" },
    { name: "복주머니", id: "bag" },
    { name: "눈사람", id: "snowman" },
  ];

  const locks = {
    basic: [
      { id: 1, src: testLockImage1 },
      { id: 2, src: testLockImage2 },
      { id: 3, src: testLockImage3 },
      { id: 4, src: testLockImage4 },
    ],
    heart: [
      { id: 5, src: testLockImage1 },
      { id: 6, src: testLockImage2 },
      { id: 7, src: testLockImage3 },
      { id: 8, src: testLockImage4 },
    ],
    letter: [
      { id: 9, src: testLockImage1 },
      { id: 10, src: testLockImage2 },
      { id: 11, src: testLockImage3 },
      { id: 12, src: testLockImage4 },
    ],
    gift: [
      { id: 13, src: testLockImage1 },
      { id: 14, src: testLockImage2 },
      { id: 15, src: testLockImage3 },
      { id: 16, src: testLockImage4 },
    ],
    bag: [
      { id: 17, src: testLockImage1 },
      { id: 18, src: testLockImage2 },
      { id: 19, src: testLockImage3 },
      { id: 20, src: testLockImage4 },
    ],
    snowman: [
      { id: 21, src: testLockImage1 },
      { id: 22, src: testLockImage2 },
      { id: 23, src: testLockImage3 },
      { id: 24, src: testLockImage4 },
    ],
  };
  const lockContainerRef = useRef({});

  const handleCardClick = (id) => {
    console.log(id);
    setSelectedCardId(id);
    setPreviousDiff(0);
    setIsSelected(true);
  };

  const handleCategoryClick = (categoryId) => {
    // const categoryElement = lockContainerRef.current[id];
    const categoryElement = document.getElementById(`${categoryId}`);
    if (categoryElement) {
      categoryElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const handleButtonClick = () => {
    if (selectedCardId) {
      dispatch(setdesign(selectedCardId));
      navigate("/create-question");
    } else {
      setIsSelected(false);
    }
  };
  return (
    <MainContainer>
      <ContentWrapper className="flex flex-col items-center">
        <header className="relative w-full max-w-[400px] flex items-center mt-12 mb-4">
          <IoIosArrowBack
            className="absolute left-0 text-3xl cursor-pointer"
            onClick={() => navigate(-1)}
          />
          <h1 className="w-full text-center font-bold text-2xl">
            자물쇠 디자인 선택
          </h1>
        </header>
        <img
          src={locks[selectedCategory][(selectedCardId - 1) % 4].src}
          alt="선택한 자물쇠"
          className="w-[200px] rounded-lg"
        />
        {/* 카테고리 선택 */}
        <section className="bg-white flex flex-col px-4 py-1 w-full rounded-md">
          <ul className="flex overflow-x-auto whitespace-nowrap scrollbar-hide">
            {categories.map((category) => (
              <li
                key={category.id}
                className="text-black px-3 py-2 rounded-full bg-gray-200 mx-1 text-sm hover:bg-gray-300"
                onClick={() => handleCategoryClick(category.id)}
              >
                {category.name}
              </li>
            ))}
          </ul>
          {/* 자물쇠 목록 */}
          <section className="flex flex-col mt-4 overflow-y-auto h-[160px] w-full max-w-[730px]">
            {Object.entries(locks).map(([categoryId, lockList]) => (
              <section
                key={categoryId}
                id={`${categoryId}`}
                ref={(el) => (lockContainerRef.current[categoryId] = el)}
                className="mb-4"
              >
                <ul className="grid grid-cols-4 gap-3">
                  {lockList.map((lock) => (
                    <LockCard
                      key={lock.id}
                      src={lock.src}
                      isSelected={selectedCardId === lock.id}
                      onClick={() => handleCardClick(lock.id)}
                    />
                  ))}
                </ul>
              </section>
            ))}
          </section>
        </section>
        <div className="flex flex-col relative py-5 items-center">
          {!isSelected && (
            <p className="absolute top-0 text-red-500">
              자물쇠를 선택해 주세요.
            </p>
          )}
          <AttractionButton onClick={handleButtonClick}>
            다음 단계
          </AttractionButton>
        </div>
      </ContentWrapper>
    </MainContainer>
  );
};

export default SelectDesignPage;
