import React, { useEffect, useState } from "react";
import naverband from "assets/share_buttons/naverband.png";
import KakaoShareButton from "./KakaoTalkShare";
import InstagramIcon from "./InstagramIcon";
import { GoLink } from "react-icons/go";
import { getLockDetail } from "apis/lockApi";

const ShareModal = ({ onClose, links, mainLockId }) => {
  const [shareLink, setShareLink] = useState("https://example.com")

  const handleCopyClick = () => {
    // Clipboard API 지원 여부 체크
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(shareLink)
        .then(() => {
          alert("링크가 clipboard api로 복사되었습니다!");
        })
        .catch((err) => {
          alert("클립보드 복사 실패");
          console.error(err);
        });
    } else {
      // Clipboard API가 없을 경우 대체 방법 사용
      const textArea = document.createElement("textarea");
      textArea.value = shareLink;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);
      alert("링크가 복사되었습니다!");
    }
  };


  useEffect(() => {
    console.log(mainLockId)
    const getLink = async(mainLockId) => {
      const response = await getLockDetail(mainLockId)
      console.log(response)
      setShareLink("https://mydevlogme.site/share?code="+response.data.shareUrl)
    }
    getLink(mainLockId)
  }, [])
  
  const handleOverlayClick = (e) => {
    e.stopPropagation();
    onClose();
  };

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
      onClick={handleOverlayClick}
    >
      <div
        className="bg-white rounded-lg p-5 w-80 h-60 flex flex-col justify-between items-center shadow-lg"
        onClick={(e) => e.stopPropagation()}
      >
        <p className="text-black font-bold">공유하기</p>
        <div className="flex flex-wrap gap-5 h-16 justify-center">
          <div className="flex flex-col items-center justify-center rounded-lg text-gray-800 text-sm font-bold cursor-pointer">
            <KakaoShareButton link={shareLink} />
            <p className="mt-2">카카오톡</p>
          </div>
          <a
            href={links.band}
            target="_blank"
            className="flex flex-col items-center justify-center rounded-lg text-gray-800 text-sm font-bold cursor-pointer"
            rel="noreferrer"
          >
            <img src={naverband} alt="Band" className="w-[70px] h-[70px]" />
            <p className="mt-2">네이버 밴드</p>
          </a>
          <a
            href={links.instagram}
            target="_blank"
            className="flex flex-col items-center justify-center rounded-lg text-gray-800 text-sm font-bold cursor-pointer"
            rel="noreferrer"
          >
            <InstagramIcon className="w-16 h-16" />
            <p className="mt-2">인스타그램</p>
          </a>
        </div>
        <div className="flex items-center justify-center gap-2 mt-5">
          <span className="text-sm text-gray-800 bg-gray-200 px-4 py-2 rounded-md max-w-[200px] text-center overflow-x-scroll scrollbar-hide whitespace-nowrap">
            {"https://mydevlogme.site/share?code="+shareLink}
          </span>
          <button
            onClick={handleCopyClick}
            className="bg-blue-800 text-white px-3 py-2 rounded-md text-sm font-bold hover:bg-blue-600"
          >
            <GoLink className="w-5 h-5" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ShareModal;
