import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userReducer from 'stores/userSlice'
import backgroundReducer from 'stores/backgroundSlice'
import mainLockReducer from "stores/mainLockSlice";
import subLockReducer from "stores/subLockSlice";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

const reducers = combineReducers({
  // 여기에 리듀서 추가
  user: userReducer,
  background: backgroundReducer,
  mainLock: mainLockReducer,
  subLock: subLockReducer,
});

const persistConfig = {
  key: 'root',
  storage,
  // 여기에 저장하고 싶은 리듀서 이름 추가
  whitelist: ['user', 'background', 'mainLock', 'subLock'],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export default configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
