import { ContentWrapper, MainContainer } from "pages/BackGround.styles";
import { useLocation } from "react-router-dom";
import { MdDeleteOutline } from "react-icons/md";
import { deleteSubLock } from "apis/lockApi";

const SubLockListPage = () => {
  const location = useLocation();
  const { subLocks } = location.state || {};
  console.log(subLocks);
  const handleDeleteClick = async (subLockId) => {
    const response = await deleteSubLock(subLockId);
    console.log(response);
  };

  return (
    <MainContainer>
      <ContentWrapper>
        <header className="flex flex-col items-center">
          <h1 className="font-bold text-2xl mt-12 mb-2 text-center">
            받은 메시지
          </h1>
        </header>
        {subLocks.map((lock) => {
          return (
            <article className="bg-white w-[360px] sm:w-[380px] md:w-[400px] lg:w-[600px] rounded-md text-black p-3">
              <div className="flex justify-between">
                <p>{lock.message || "메시지가 없습니다."}</p>
                <MdDeleteOutline
                  onClick={() => handleDeleteClick(lock.subLockId)}
                />
              </div>
              <p className="text-right">by {lock.ownerName}</p>
            </article>
          );
        })}
      </ContentWrapper>
    </MainContainer>
  );
};

export default SubLockListPage;
