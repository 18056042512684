import React, { useEffect, useState } from "react";
import { ContentWrapper, MainContainer } from "../BackGround.styles";
import LockSlider from "./LockSlider";
import BottomTab from "../../components/BottomTab";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getLockList } from "apis/lockApi";
import SubmitButton from "components/SubmitButton";
import { useSelector } from "react-redux";

export default function AttractionMainPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [data, setData] = useState(null);
  const [fetchPage, setFetchPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [previewData, setPreviewData] = useState(null);

  // 네 귀퉁이 테두리 토글 상태 관리
  const [isCornersVisible, setIsCornersVisible] = useState(true);

  // 쿼리에서 hotPlaceId 가져오기
  const hotPlaceId = searchParams.get("hotPlaceId") || 1; // 기본값 1 설정
  const placeName = useSelector((state) => state.background.backgroundName) || "명소 이름 없음";

  useEffect(() => {
    const fetchData = async (page) => {
      try {
        const response = await getLockList(hotPlaceId, page);
        let locks = response.data.content;
        if (locks.length === 0) {
          setHasMore(false);
          return;
        }

        const remainder = locks.length % 6;
        if (remainder !== 0) {
          const placeholders = Array.from({ length: 6 - remainder }, (_, i) => ({
            mainLockId: `placeholder-${page}-${i}`,
            mainLockImage: null,
            ownerName: "",
            message: "",
          }));
          locks = [...locks, ...placeholders];
        }

        setData((prevData) => (prevData ? [...prevData, ...locks] : locks));
      } catch (error) {
        console.error("Error fetching lock list:", error);
      }
    };

    fetchData(fetchPage);
  }, [hotPlaceId, fetchPage]);

  const textStyle = {
    textShadow: "2px 2px 4px rgba(0, 0, 0, 2)",
  };

  const handleNavigate = () => {
    navigate(`/main-lock-detail?mainLockId=${previewData.mainLockId}`, {
      state: previewData,
    });
  };

  return (
    <MainContainer>
      <ContentWrapper>
        {/* 뒤로가기 버튼 */}
        <IoIosArrowBack
          className="absolute left-5 top-20 text-3xl cursor-pointer"
          onClick={() => navigate(-1)}
        />

        {/* REC 빨간 점 */}
        {isCornersVisible && (
          <div
            className="absolute flex items-center text-white font-bold text-lg"
            style={{ top: "4%", left: "16%", transform: "translateX(-50%)" }}
          >
            <div
              style={{
                width: "10px",
                height: "10px",
                backgroundColor: "red",
                borderRadius: "50%",
                marginRight: "8px",
                animation: "blink 1s infinite",
              }}
            ></div>
            REC
          </div>
        )}

        {/* 네 귀퉁이 테두리 */}
        {isCornersVisible && (
          <>
            <div className="absolute top-5 left-5 w-8 h-8 border-t-4 border-l-4 border-white pointer-events-none"></div>
            <div className="absolute top-5 right-5 w-8 h-8 border-t-4 border-r-4 border-white pointer-events-none"></div>
            <div className="absolute bottom-5 left-5 w-8 h-8 border-b-4 border-l-4 border-white pointer-events-none"></div>
            <div className="absolute bottom-5 right-5 w-8 h-8 border-b-4 border-r-4 border-white pointer-events-none"></div>
          </>
        )}

        {/* 테두리 토글 버튼 */}
        <button
          onClick={() => setIsCornersVisible((prev) => !prev)}
          className="absolute text-white bg-black bg-opacity-60 p-2 rounded-md text-sm"
          style={{ top: "4%", right: "9%" }}
        >
          {isCornersVisible ? "테두리 끄기" : "테두리 켜기"}
        </button>

        <header className="flex flex-col items-center">
          <div className="flex flex-row items-center">
            <h1 className="font-bold text-2xl mt-12 mb-2">명소 둘러보기</h1>
          </div>
          <h3 className="text-lg">장소: {placeName}</h3>
        </header>
        {previewData && (
          <article className="flex flex-col items-center p-4 bg-white bg-opacity-30 rounded-xl w-80">
            <div className="relative w-40 h-40">
              <img
                src={previewData.imgPath}
                alt="미리보기 이미지"
                className="w-full h-full object-contain"
              />
              <p
                className="absolute inset-0 flex items-center justify-center text-white text-lg font-bold"
                style={textStyle}
              >
                {previewData.message}
              </p>
            </div>
            <div className="grid grid-cols-4 gap-2 w-full">
            {previewData.subLockPreviews.length > 0 ? (
              previewData.subLockPreviews.map((item, index) => (
                <div
                  key={index}
                  className="flex flex-col items-center text-center"
                >
                  <img
                    src={require(`assets/${item.subLockImage}`)}
                    alt={item.ownerName}
                    className="w-full aspect-square object-contain"
                  />
                  <p className="text-xs font-medium">{item.ownerName}</p>
                </div>
              ))
            ) : (
              <p className="col-span-4 text-center text-gray-100 text-md font-semibold my-6">
                서브 자물쇠가 없습니다.
              </p>
            )}
          </div>

            <SubmitButton
              onClick={handleNavigate}
            >
              이 자물쇠 전체보기
            </SubmitButton>
          </article>
        )}
        <section className="my-4">
          <LockSlider
            data={data}
            fetchMoreData={() => setFetchPage((prev) => prev + 1)}
            hasMore={hasMore}
            setPreviewData={setPreviewData}
          />
        </section>
        <BottomTab />
      </ContentWrapper>
    </MainContainer>
  );
}
