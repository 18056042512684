import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userId: "",
  nickname: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserInfo: (state, action) => {
      const { userId, nickname } = action.payload;
      state.userId = userId;
      state.nickname = nickname;
    },
    clearUserInfo: (state) => {
      state.userId = "";
      state.nickname = "";
    },
    updateNickname: (state, action) => {
      state.nickname = action.payload;
    },
  },
});

export const { setUserInfo, clearUserInfo, updateNickname } = userSlice.actions
export default userSlice.reducer