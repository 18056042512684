import { useNavigate } from "react-router-dom";
import AttractionButton from "../AttractionPage/AttractionButton";
import { ContentWrapper, MainContainer } from "../BackGround.styles";
import { IoIosArrowBack } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { createLock } from "apis/lockApi";
import { setMessage } from "stores/mainLockSlice";

const LockQuestionPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const hotPlaceId = useSelector((state) => state.mainLock.location);
  const design = useSelector((state) => state.mainLock.design);
  const [lockmessage, setLockMessage] = useState("");
  const maxLength = 50;

  const handleInputChange = (e) => {
    const input = e.target.value;

    // 한글 글자 수 계산
    const length = input.length;

    // 50자를 초과하면 기존 값 유지
    if (length <= maxLength) {
      setLockMessage(input);
    }
  };
  const handleButtonClick = async () => {
    dispatch(setMessage(lockmessage));
    // console.log(hotPlaceId, design, lockmessage)
    const response = await createLock({
      hotPlaceId,
      mainLockImageId: design,
      message: lockmessage,
    });
    console.log(response.data.mainLockId);
    navigate("/create-result", {
      state: { mainLockId: response.data.mainLockId },
    });
  };
  return (
    <MainContainer>
      <ContentWrapper className="flex flex-col items-center">
        <header className="relative w-full max-w-[400px] flex items-center mt-12 mb-4">
          <IoIosArrowBack
            className="absolute left-0 text-3xl cursor-pointer"
            onClick={() => navigate(-1)}
          />
          <h1 className="w-full text-center font-bold text-2xl">
            자물쇠 메시지 작성
          </h1>
        </header>
        <form className="w-full" onSubmit={(e) => e.preventDefault()}>
          <textarea
            value={lockmessage}
            onChange={handleInputChange}
            className="text-black
          w-full rounded-xl p-4
          resize-none"
            placeholder={`최대 ${maxLength}자 입력 가능합니다.`}
          />
          <p
            className={`text-right text-sm mt-1 ${
              lockmessage.length === maxLength
                ? "text-red-500"
                : "text-gray-500"
            }`}
          >
            {lockmessage.length}/{maxLength}자
          </p>
        </form>
        <AttractionButton className="mb-4" onClick={handleButtonClick}>
          완료하기
        </AttractionButton>
      </ContentWrapper>
    </MainContainer>
  );
};

export default LockQuestionPage;
