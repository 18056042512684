import { useLocation, useNavigate } from "react-router-dom";
import AttractionButton from "../AttractionPage/AttractionButton";
import { ContentWrapper, MainContainer } from "../BackGround.styles";
import { useEffect, useRef, useState } from "react";
import ShareModal from "./ShareModal";
import image1 from "assets/attractions/attraction-1.png";
import image2 from "assets/attractions/attraction-2.png";
import image3 from "assets/attractions/attraction-3.png";
import image4 from "assets/attractions/attraction-4.png";
import image5 from "assets/attractions/attraction-5.png";
import image6 from "assets/attractions/attraction-6.png";
import lockImage1 from "assets/locks/lock-1.png";
import lockImage2 from "assets/locks/lock-2.png";
import lockImage3 from "assets/locks/lock-3.png";
import lockImage4 from "assets/locks/lock-4.png";
import { BsDownload } from "react-icons/bs";
import { useSelector } from "react-redux";
import LomHeader from "components/LomHeader";

const CreateResultPage = () => {
  const userName = useSelector((state) => state.user.nickname);
  const userMessage = useSelector((state) => state.mainLock.message);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const hotPlaceId = useSelector((state) => state.mainLock.location);
  const design = useSelector((state) => state.mainLock.design);
  const location = useLocation();
  const { mainLockId } = location.state || {};
  console.log(mainLockId);
  const navigate = useNavigate();

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const canvasRef = useRef(null);

  const getImageSrc = (status) => {
    switch (status) {
      case 1:
        return image1;
      case 2:
        return image2;
      case 3:
        return image3;
      case 4:
        return image4;
      case 5:
        return image5;
      case 6:
        return image6;
      default:
        return image1;
    }
  };

  const getLockImageSrc = (status) => {
    switch (status) {
      case 1:
        return lockImage1;
      case 2:
        return lockImage2;
      case 3:
        return lockImage3;
      case 4:
        return lockImage4;
      default:
        return lockImage1;
    }
  };

  const truncateText = (ctx, text) => {
    let truncated = text;
    while (
      ctx.measureText(truncated + "...").width > 512 &&
      truncated.length > 0
    ) {
      truncated = truncated.slice(0, -1); // 텍스트 길이를 하나씩 줄임
    }
    return truncated.length < text.length ? truncated + "..." : text;
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    canvas.width = 1000;
    canvas.height = 1000;

    const image = new Image();
    image.src = getImageSrc(hotPlaceId);

    const lockImage = new Image();
    lockImage.src = getLockImageSrc(design); // 추가 이미지 경로

    image.onload = () => {
      // 배경색상
      ctx.fillStyle = "white";
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      // 이미지 렌더링
      ctx.drawImage(image, 45, 45, canvas.width * 0.91, canvas.width * 0.61);

      // 텍스트 추가
      ctx.font = "40px Arial";
      ctx.fillStyle = "#868686";
      ctx.fillText("# Lock Our Memory", 50, 750);
      ctx.fillText("# 마음을 전해요", 50, 810);

      // 동적 텍스트 위치 계산
      const userText = `From. ${userName}`;
      // 메시지와 사용자 텍스트를 축약
      const truncatedMessage = truncateText(ctx, userMessage);
      const truncatedUserText = truncateText(ctx, userText);
      const messageWidth = ctx.measureText(truncatedMessage).width; // 텍스트 폭 측정
      const userTextWidth = ctx.measureText(truncatedUserText).width;

      ctx.fillStyle = "black";
      ctx.fillText(truncatedMessage, canvas.width - messageWidth - 50, 750); // 오른쪽 여백 50px
      ctx.fillText(truncatedUserText, canvas.width - userTextWidth - 50, 850);

      // 날짜 정렬
      const date = new Date();
      const dateText =
        date.getFullYear() + "." + (date.getMonth() + 1) + "." + date.getDate();
      const dateWidth = ctx.measureText(dateText).width;
      ctx.fillText(dateText, canvas.width - dateWidth - 50, 910);

      // 이미지 추가
      lockImage.onload = () => {
        const imageSize = 60; // 추가 이미지 크기
        const imageX = canvas.width - dateWidth - 50 - imageSize - 10; // 사용자 텍스트 왼쪽 위치
        const imageY = 900 - imageSize / 2; // 텍스트와 이미지의 세로 위치 정렬

        ctx.drawImage(lockImage, imageX, imageY, imageSize, imageSize);

        ctx.fillStyle = "black";
        ctx.fillText(
          truncatedMessage,
          canvas.width - ctx.measureText(truncatedMessage).width - 50,
          750
        );
        ctx.fillText(truncatedUserText, canvas.width - userTextWidth - 50, 850);
      };

      // 중앙 URL
      ctx.fillStyle = "#ED8F8F";
      ctx.fillText("lockourmemory.site", 50, 910);
    };
  }, []);

  const handleSaveImage = () => {
    const canvas = canvasRef.current;
    const dataUrl = canvas.toDataURL("image/png");

    // 다운로드 링크 생성 및 트리거
    const link = document.createElement("a");
    link.href = dataUrl;
    link.download = "edited-image.png";
    link.click();
  };

  return (
    <MainContainer>
      <ContentWrapper className="flex flex-col items-center">
        <LomHeader title={"자물쇠 공유하기"} />

        <article className="w-full max-w-[600px] aspect-square my-6 relative">
          <canvas
            ref={canvasRef}
            style={{ width: "100%", height: "auto" }} // 화면 폭에 맞게 조정
          />
          <button
            onClick={handleSaveImage}
            className="right-0 bottom-0 px-3 pb-2 bg-white text-black rounded absolute"
          >
            <BsDownload />
          </button>
        </article>

        <section className="flex flex-col items-center w-full max-w-[300px] mb-8">
          <AttractionButton className="w-full" onClick={openModal}>
            공유하기
          </AttractionButton>
          <AttractionButton
            className="w-full"
            onClick={() => navigate("/home")}
          >
            메인으로 가기
          </AttractionButton>
        </section>
        {isModalOpen && (
          <ShareModal
            onClose={closeModal}
            links={{
              kakao: "https://kakao.com",
              band: "https://band.us",
              instagram: "https://instagram.com",
              // site: "https://example.com",
            }}
            copyLink="https://www.extra-long-link-example.com"
            mainLockId={mainLockId}
          />
        )}
      </ContentWrapper>
    </MainContainer>
  );
};

export default CreateResultPage;
