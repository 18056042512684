import React from "react";
import Router from "./Router";
import { createGlobalStyle } from "styled-components";
import styled from "styled-components"; 
import { useSelector } from "react-redux";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Arial, sans-serif;
    display: flex;
    justify-content: center;
    background-color: #f8f9fa; /* 양 옆의 하얀 여백을 나타내기 위해 설정 */
  }
`;

const MainContainer = styled.div`
  width: 100vw;
  max-width: 1024px;
  min-width: 375px;
  min-height: 667px;
  height: 100vh;
  background-color: #0d3b74; /* 가운데 영역의 배경색 */
  background-image: url(${(props) => props.bgimage}); /* 배경 이미지 설정 */
  background-size: cover; /* 이미지를 컨테이너에 맞게 조정 */
  background-position: center; /* 이미지를 가운데에 위치시킴 */
  color: white;
  margin: 0 auto;
`;

function App() {
  const backgroundImage = useSelector((state) => state.background.backgroundImage)
  return (
    <>
      <GlobalStyle />
      <MainContainer bgimage={backgroundImage}>
        <Router />
      </MainContainer>
    </>
  );
}

export default App;
