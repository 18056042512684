import { useState } from "react";
import checkIcon from "../../assets/check.png";
import ReusableModal from "../../components/ReusableModal";
import { useNavigate } from "react-router-dom";
import { cancelAccount, updateUserInfo } from "../../apis/userApi";
import InputContainer from "../../components/InputContainer";
import SubmitButton from "../../components/SubmitButton";
import ConfirmModal from "./ConfirmModal";
import { useSelector, useDispatch } from "react-redux";
import { updateNickname, clearUserInfo } from "stores/userSlice";

const InfoUpdateForm = () => {
  const user = useSelector((state) => state.user); // Redux 상태에서 user 가져오기
  const [nickname, setNickname] = useState(user.nickname); // 초기값을 Redux에서 가져오기
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const openModal = () => setIsModalOpen(true);

  const handleNicknameChange = (e) => {
    setNickname(e.target.value);
  };

  const handleUpdate = async () => {
    const response = await updateUserInfo({ nickname }); // 서버에 닉네임 업데이트 요청
    console.log(response);

    // Redux 상태 업데이트
    dispatch(updateNickname(nickname));

    navigate('/my-page'); // 페이지 이동
  };

  const openConfirmModal = () => setIsConfirmModalOpen(true);
  const closeConfirmModal = () => setIsConfirmModalOpen(false);

  const handleConfirmCancelAccount = async () => {
    const response = await cancelAccount(); // 탈퇴 요청
    console.log(response);

    dispatch(clearUserInfo()); // Redux 상태 초기화
    navigate("/"); // 메인 페이지로 이동
  };

  return (
    <div className="flex flex-col w-full h-full max-w-[500px] justify-between">
      <div className="flex flex-col py-14">
        <div className="flex flex-col">
          <p className="font-bold ml-3">닉네임</p>
          <InputContainer
            placeholder="* 닉네임을 입력하세요."
            value={nickname}
            type="text"
            onChange={handleNicknameChange}
          />
        </div>
      </div>
      <div className="flex flex-col">
        <SubmitButton onClick={openModal}>
          <p>수정하기</p>
        </SubmitButton>
        <SubmitButton onClick={openConfirmModal}>
          탈퇴하기
        </SubmitButton>
      </div>
      {isModalOpen && (
        <ReusableModal
          iconSrc={checkIcon}
          title="회원정보 수정이 완료되었습니다."
          message="메인 화면으로 이동 후 이용해주세요."
          buttonText="메인 화면으로 돌아가기"
          onClose={handleUpdate}
        />
      )}
      {isConfirmModalOpen && (
        <ConfirmModal
          title="정말 탈퇴하시겠습니까?"
          onConfirm={handleConfirmCancelAccount}
          onCancel={closeConfirmModal}
        />
      )}
    </div>
  );
};

export default InfoUpdateForm;
