import { ContentWrapper, MainContainer } from "../BackGround.styles";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { GrUserSettings } from "react-icons/gr";
import { IoIosArrowBack } from "react-icons/io";
import { BsThreeDotsVertical } from "react-icons/bs"; // 세로 점 세 개 아이콘
import {
  AiOutlineCalendar,
  AiOutlineEye,
  AiOutlineEyeInvisible,
  AiOutlineLock,
} from "react-icons/ai";
import { getMyMainLock, getMySubLock } from "apis/lockApi";

const MyPage = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("lock");
  const [mainLockData, setMainLockData] = useState(null);
  const [subLockData, setSubLockData] = useState(null);
  const [openModalId, setOpenModalId] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);


  // 탭 클릭 핸들러
  const handleTabClick = (tab) => setActiveTab(tab);

  // 데이터 가져오기
  useEffect(() => {
    const fetchMainData = async () => {
      try {
        const response = await getMyMainLock();
        setMainLockData(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchSubData = async () => {
      try {
        const response = await getMySubLock();
        setSubLockData(response.data);
        console.log(response.data)
      } catch (error) {
        console.error(error);
      }
    };

    fetchMainData();
    fetchSubData();
  }, []);

  // mainLockData를 렌더링에 적합한 형식으로 변환
  const lockData = mainLockData
    ? mainLockData.map((lock) => ({
        id: lock.mainLockId,
        image: lock.mainLockImage,
        title: lock.message || "제목 없음",
        date: lock.createDate || "알 수 없는 날짜",
        views: lock.views || 0,
        likes: lock.subLockCount || 0,
      }))
    : [];

  // subLockData를 렌더링에 적합한 형식으로 변환
  const messageData = subLockData
    ? subLockData.map((sub) => ({
        id: sub.mainLockId,
        subLockId: sub.subLockId,
        image: sub.subLockImage,
        sender: sub.ownerName || "익명",
        title: sub.message || "메시지 없음",
        date: sub.createDate || "알 수 없는 날짜",
        visible: sub.visible,
      }))
    : [];

  const dataToShow = activeTab === "lock" ? lockData : messageData;

  const handleNavigate = (mainLockId) => {
    navigate(`/main-lock-detail?mainLockId=${mainLockId}`);
  };

  const handleModalToggle = (id) => {
    setOpenModalId((prevId) => (prevId === id ? null : id));
  };

  return (
    <MainContainer>
      <ContentWrapper>
        <div className="w-[95%]">
          {/* 헤더 */}
          <div className="relative w-full flex items-center justify-between px-4 my-10">
            <IoIosArrowBack
              className="text-white text-3xl cursor-pointer"
              onClick={() => navigate(-1)}
            />
            <h1 className="text-center text-white text-xl font-bold">
              나의 자물쇠 보기
            </h1>
            <GrUserSettings
              className="text-white text-3xl cursor-pointer"
              onClick={() => navigate("/info-update")}
            />
          </div>

          {/* 탭 및 컨텐츠 */}
          <div className="w-full bg-white rounded-lg shadow-lg mt-4 py-2">
            {/* 탭 메뉴 */}
            <div className="flex">
              <button
                className={`flex-1 py-2 text-center font-bold ${
                  activeTab === "lock"
                    ? "text-blue-500 border-b-2 border-blue-500"
                    : "text-gray-500"
                }`}
                onClick={() => handleTabClick("lock")}
              >
                추억 자물쇠
              </button>
              <button
                className={`flex-1 py-2 text-center font-bold ${
                  activeTab === "message"
                    ? "text-blue-500 border-b-2 border-blue-500"
                    : "text-gray-500"
                }`}
                onClick={() => handleTabClick("message")}
              >
                메시지
              </button>
            </div>
            {isEditModalOpen && (
              <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                <form
                  className="bg-white rounded-lg shadow-lg p-6 w-80"
                  onSubmit={(e) => e.preventDefault()}
                >
                  <h2 className="text-xl font-bold mb-4 text-black text-center">
                    자물쇠 내용 수정
                  </h2>
                  <input
                    type="text"
                    value=""
                    onChange={() => {}}
                    placeholder=""
                    className="w-full border border-gray-300 rounded-lg p-3 mb-4 text-stone-600"
                  />
                  <div className="flex justify-end space-x-3">
                    <button
                      onClick={() => setIsEditModalOpen(false)}
                      className="px-4 py-2 bg-gray-200 text-gray-800 rounded-lg"
                    >
                      취소
                    </button>
                    <button
                      onClick={() => {}}
                      className="px-4 py-2 bg-blue-500 text-white rounded-lg"
                    >
                      완료
                    </button>
                  </div>
                </form>
              </div>
            )}

            {/* 컨텐츠 리스트 */}
            <div className="p-4 h-[60vh] overflow-y-auto bg-white w-full">
              {dataToShow.map((item) => (
                <div
                key={activeTab === "lock" ? item.id : item.subLockId}
                className="flex flex-col p-4 mb-4 rounded-lg bg-gray-50 shadow-sm relative"
              >
              
                  {/* mainLock (추억 자물쇠) 스타일 */}
                  {activeTab === "lock" && (
                    <div className="flex items-center mb-2 relative">
                      <img
                        src={require(`assets/${item.image}`)}
                        alt="lock"
                        className="w-12 h-12 rounded-full object-cover"
                      />
                      <div className="ml-4 flex-1">
                        <h2 className="font-bold text-gray-700 truncate">
                          {item.title}
                        </h2>
                        <div className="flex items-center text-sm text-gray-500 mt-1">
                          <AiOutlineCalendar className="mr-1" />
                          {item.date}
                          <AiOutlineEye className="ml-4 mr-1" />
                          {item.views}
                          <AiOutlineLock className="ml-4 mr-1 text-red-500" />
                          {item.likes}
                        </div>
                      </div>
                      <BsThreeDotsVertical
                        className="absolute right-0 top-0 text-gray-500 cursor-pointer"
                        onClick={() => handleModalToggle(item.id)}
                      />
                      {/* 모달 */}
                      {openModalId === item.id && (
                        <div className="absolute right-0 top-4 bg-white border shadow-lg rounded-lg p-2 z-10">
                          <button 
                          className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          onClick={() => setIsEditModalOpen(true)}
                          >
                            수정
                          </button>
                          <button className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                            삭제
                          </button>
                        </div>
                      )}
                    </div>
                  )}

                  {/* message (메시지) 스타일 */}
                  {activeTab === "message" && (
                    <div className="flex items-center mb-2 relative">
                      <img
                        src={require(`assets/${item.image}`)}
                        alt="message"
                        className="w-12 h-12 rounded-full object-cover"
                      />
                      <div className="ml-4 flex-1">
                        <p className="text-gray-500 text-sm">
                          {`${item.sender}에게`}
                        </p>
                        <p className="font-bold text-gray-700">{item.title}</p>
                        <div className="flex items-center text-sm mt-1">
                          <AiOutlineCalendar className="mr-1 text-gray-500" />
                          <span className="text-gray-500">{item.date}</span>
                          {item.visible ? (
                            <>
                              <AiOutlineEye className="ml-4 mr-1 text-blue-500" />
                              <span className="text-blue-500">공개</span>
                            </>
                          ) : (
                            <>
                              <AiOutlineEyeInvisible className="ml-4 mr-1 text-red-500" />
                              <span className="text-red-500">비공개</span>
                            </>
                          )}
                        </div>
                      </div>
                      <BsThreeDotsVertical
                        className="absolute right-0 top-0 text-gray-500 cursor-pointer"
                        onClick={() => handleModalToggle(item.id)}
                      />
                      {/* 모달 */}
                      {openModalId === item.id && (
                        <div className="absolute right-0 top-4 bg-white border shadow-lg rounded-lg p-2 z-10">
                          <button 
                            className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            onClick={() => setIsEditModalOpen(true)}>
                            수정
                          </button>
                          <button className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                            삭제
                          </button>
                        </div>
                      )}
                    </div>
                  )}

                  {/* 자세히 보기 버튼 */}
                  <div className="flex justify-end">
                    <button
                      className="text-blue-500 text-sm"
                      onClick={() => handleNavigate(item.id)}
                    >
                      자세히 보기
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </ContentWrapper>
    </MainContainer>
  );
};

export default MyPage;
