import styled from "styled-components";

// export const ContentWrapper = styled.div`
//   display: flex;
//   min-height: 667px;
//   flex: 1;
//   flex-direction: column;
//   justify-content: space-between;
//   height: 100vh;
//   position: relative;
//   z-index: 2;
//   padding: 20px;
//   color: white;
// `;

export const ButtonContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 0 auto;
`;

export const Button = styled.button`
  height: 50px;
  max-width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.$bgcolor || "#fff"};
  color: ${(props) => props.$textcolor || "#000"};
  border-radius: 12px;
  font-size: 15px;
  font-weight: 400;
  cursor: pointer;
  padding: 0 70px; /* 텍스트 좌우 여백 8dp */
`;

export const Icon = styled.div`
  width: 40px;
  height: 40px;
  margin-right: 10px;
  display: inline-block;
  background-size: cover;
  background-position: center;
`;

export const SignUpText = styled.p`
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  color: #fff;
  text-decoration: underline;
`;
