import {
  MainContainerDark,
  InputContainer,
  StyledInput,
  ContentWrapper,
} from "../BackGround.styles";
import { Button } from "./LoginMainPage.styles";

export default function CreateNickNamePage() {
  return (
    <MainContainerDark>
      <ContentWrapper>
        <h2 className="font-bold text-center mt-20 text-2xl">
          우리의 추억 자물쇠
        </h2>
        <p className="text-center mt-4">Lock Our Memory</p>

        <InputContainer>
          <p className="font-bold">닉네임</p>
          <StyledInput
            type="text"
            placeholder="닉네임을 적어주세요 ex) 산타클로스"
          />
        </InputContainer>

        <Button $bgcolor="#F0F0F0" $textcolor="#000" className="mx-5 mt-96">
          <span style={{ flex: 1, textAlign: "center", padding: 6 }}>
            계정 만들기
          </span>
        </Button>
      </ContentWrapper>
    </MainContainerDark>
  );
}
