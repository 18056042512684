const LockCard = ({ src, isSelected, onClick }) => {
  return (
    <div
      onClick={onClick} // 부모 컴포넌트에서 전달된 클릭 핸들러
      className={`
        flex justify-center items-center 
        rounded-xl
        cursor-pointer
      `}
    >
      <img
        className={`w-[70px] h-[70px]
        sm:w-[110px] md:w-[150px] lg:w-[200px] xl:w-[250px]
        sm:h-[110px] md:h-[150px] lg:h-[200px] xl:h-[250px]
        rounded-xl aspect-square
        ${
          isSelected ? "border-4 border-blue-500" : "border-4 border-transparent"
        } 
        `}
        src={src}
        alt="Lock"
      />
    </div>
  );
};

export default LockCard;
