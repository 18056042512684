import { useState } from "react";
import SignInInput from "./SignInInput";
import SignInButton from "./SignInButton";
import { signIn } from "../../apis/userApi";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { updateNickname } from "stores/userSlice";
import { useDispatch } from "react-redux";

const SignInForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSignIn = async () => {
    // console.log(email, password);
    const response = await signIn({
      email: email,
      password: password,
    });
    // console.log(response.headers.authorization)
    axios.defaults.headers.common["Authorization"] =
      response.headers.authorization;
    dispatch(updateNickname(response.data.nickname));
    navigate("/home");
  };

  return (
    <form className="flex flex-col w-full h-full max-w-[500px] justify-between"
    onSubmit={(e) => e.preventDefault()}>
      <div className="flex flex-col pt-14">
        <label className="font-bold ml-3">이메일</label>
        <SignInInput
          placeholder="* 이메일을 입력해주세요."
          value={email}
          type="email"
          onChange={handleEmailChange}
        />
        <label className="font-bold ml-3">비밀번호</label>
        <SignInInput
          placeholder="* 비밀번호를 입력해주세요."
          value={password}
          type="password"
          onChange={handlePasswordChange}
        />
      </div>
      <SignInButton onClick={() => handleSignIn()}>
        <p>로그인</p>
      </SignInButton>
    </form>
  );
};

export default SignInForm;
