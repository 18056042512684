import API from "../API"

// 메인 자물쇠
export const createLock = async (data) => {
    return await API().post("/main-lock", data)
    .then((response) => response)
    .catch((error) => error)
}

export const getLockList = async (hotPlaceId, pageNumber) => {
    return await API().get(`/main-lock/list?hotPlaceId=${hotPlaceId}&pageNumber=${pageNumber}`)
    .then((response) => response)
    .catch((error) => error)
}

export const getLockDetail = async (mainLockId) => {
    return await API().get(`/main-lock/detail?mainLockId=${mainLockId}`)
    .then((response) => response)
    .catch((error) => error)
}

export const deleteLock = async (mainLockId) => {
    return await API().delete(`/main-lock?mainLockId=${mainLockId}`,)
}

export const updateLock = async (data) => {
    return await API().put("/main-lock", data)
    .then((response) => response)
    .catch((error) => error)
}

// 서브 자물쇠
export const createSubLock = async (data) => {
    return await API().post("/sub-lock", data)
    .then((response) => response)
    .catch((error) => error)
}

export const patchLock = async (data) => {
    return await API().put("/main-lock", data)
    .then((response) => response)
    .catch((error) => error)
}
export const updateSubLock = async (data) => {
    return await API().put("/sub-lock", data)
    .then((response) => response)
    .catch((error) => error)
}

export const deleteSubLock = async (subLockId) => {
    return await API().delete(`/sub-lock?subLockId=${subLockId}`)
    .then((response) => response)
    .catch((error) => error)
}

export const getMySubLockList = async () => {
    return await API().get("/sub-lock/my-list")
    .then((response) => response)
    .catch((error) => error)
}

export const getSubLockDetail = async (subLockId) => {
    return await API().get(`/sub-lock/detail?subLockId=${subLockId}`)
    .then((response) => response)
    .catch((error) => error)
}

export const mainLockPreview = async (mainLockId) => {
    return await API().get(`/main-lock/preview?mainLockId=${mainLockId}`)
    .then((response) => response)
    .catch((error) => error)
}

export const getMyMainLock = async () => {
    return await API().get("/main-lock/my-list")
    .then((response) => response)
    .catch((error) => error)
}

export const getMySubLock = async () => {
    return await API().get("/sub-lock/my-list")
    .then((response) => response)
    .catch((error) => error)
}