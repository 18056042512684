import React from "react";
import myPage from "assets/my-page.png"
import bottomLock from "assets/bottom-lock.png"
import refresh from "assets/refresh.png"


export default function BottomTab({ onHomeClick, onLockClick, onRecordClick }) {
  return (
    <nav
      className="fixed bottom-0 left-0 w-full flex justify-between items-center bg-black text-white py-3 px-5"
      style={{
        borderTop: "1px solid rgba(255, 255, 255, 0.2)",
      }}
    >
      <button
        className="flex flex-col items-center cursor-pointer"
        onClick={onHomeClick}
      >
        <img 
          className="w-8 mb-2"
          src={myPage} alt="" />
        <span className="text-sm mt-1">마이페이지</span>
      </button>
      <button
        className="flex flex-col items-center cursor-pointer"
        onClick={onLockClick}
      >
        <img
          className="w-12 mb-2"
          src={bottomLock} alt="" />
        <span className="text-sm mt-1">이 명소에 자물쇠 걸기</span>
      </button>
      <button
        className="flex flex-col items-center cursor-pointer"
        onClick={onRecordClick}
      >
        <img 
          className="w-9 mb-2"
          src={refresh} alt="" />
        <span className="text-sm mt-1">새로고침</span>
      </button>
    </nav>
  );
}
