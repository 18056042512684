import { ContentWrapper, MainContainer } from "../BackGround.styles";
import AtrractionCard from "../AttractionPage/AtrractionCard";
import { useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import AttractionButton from "../AttractionPage/AttractionButton";
import image1 from "assets/attractions/attraction-1.png";
import image2 from "assets/attractions/attraction-2.png";
import image3 from "assets/attractions/attraction-3.png";
import image4 from "assets/attractions/attraction-4.png";
import image5 from "assets/attractions/attraction-5.png";
import image6 from "assets/attractions/attraction-6.png";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setBackgroundImage, setBackgroundName } from "stores/backgroundSlice";
import AtrractionPolaroidCard from "./AtrractionPolaroidCard";

const AttractionPage = () => {
  const [selectedCardId, setSelectedCardId] = useState(null);
  const [isSelected, setIsSelected] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const cards = [
    { id: 0, src: image1, name: "남산 타워" },
    { id: 1, src: image2, name: "경주 하서항" },
    { id: 2, src: image3, name: "명동 성당" },
    { id: 3, src: image4, name: "포항 호미곶" },
    { id: 4, src: image5, name: "별마당 도서관" },
    { id: 5, src: image6, name: "성산 일출봉" },
  ];

  const handleCardClick = (id, src, name) => {
    setSelectedCardId(id);
    setIsSelected(true);
    // Redux 상태 업데이트
    dispatch(setBackgroundImage(src));
    dispatch(setBackgroundName(name));
  };

  const handleNextStep = () => {
    if (selectedCardId === null) {
      setIsSelected(false);
      return;
    }

    // 쿼리 파라미터로 hotPlaceId 전달
    navigate(`/attraction-main?hotPlaceId=${selectedCardId + 1}`);
  };

  return (
    <MainContainer>
      <ContentWrapper>
        <header className="relative w-full mt-12 mb-4 flex justify-center items-center">
          <IoIosArrowBack
            className="absolute left-0 text-3xl cursor-pointer"
            onClick={() => navigate(-1)}
          />
          <div className="flex flex-col items-center">
            <h1 className="text-center font-bold text-2xl">명소 선택하기</h1>
            {selectedCardId !== null ? (
              <h3 className="pt-2">{"<" + cards[selectedCardId].name + ">"}</h3>
            ) : (
              <h3 className="pt-2">명소를 선택해 주세요.</h3>
            )}
          </div>
        </header>
        <section
          className="grid grid-cols-2 gap-3 
                    place-items-center
                    w-full max-w-[700px] max-h-[1000px]
                    mb-2 mx-4 "
        >
          {cards.map((card) => (
            <AtrractionPolaroidCard
              key={card.id}
              src={card.src}
              placeName={card.name}
              isSelected={selectedCardId === card.id} // 선택 상태 전달
              onClick={() => handleCardClick(card.id, card.src, card.name)} // 클릭 핸들러 전달
            />
          ))}
        </section>
        <div className="flex flex-col relative py-5 items-center">
          {!isSelected && (
            <p className="absolute top-0 text-red-500">명소를 선택해 주세요.</p>
          )}
          <AttractionButton onClick={handleNextStep}>
            다음 단계
          </AttractionButton>
        </div>
      </ContentWrapper>
    </MainContainer>
  );
};

export default AttractionPage;
