const MessageModal = ({ onClose, data }) => {
    console.log(data)
  const handleOverlayClick = (e) => {
    e.stopPropagation();
    onClose();
  };
  return (
    <div
      className="fixed inset-0 flex justify-center items-center z-50"
      onClick={handleOverlayClick}
    >
      <div
        className="bg-white rounded-lg p-5 w-80 h-30 flex flex-col justify-between items-center shadow-lg"
        onClick={(e) => e.stopPropagation()}
      >
        <h2 className="text-black">{data.message || "메시지내용"}</h2>
        <p className="text-black">by {data.ownerName}</p>
      </div>
    </div>
  );
};

export default MessageModal;
