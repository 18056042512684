import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ContentWrapper, MainContainer } from "pages/BackGround.styles";
import { IoIosArrowBack } from "react-icons/io";
import { FiMoreVertical } from "react-icons/fi"; // 점 세 개 아이콘
import LockDetailSlider from "./LockDetailSlider";
import { useDispatch, useSelector } from "react-redux";
import { deleteLock, updateLock, getLockDetail } from "apis/lockApi"; // API 추가
import MainLockDetailBottomTab from "./MainLockDetailBottomTab";
import { setLockId } from "stores/subLockSlice";

export default function MainLockDetailPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams(); // URL에서 파라미터 추출
  const mainLockId = searchParams.get("mainLockId"); // mainLockId 추출
  const userId = useSelector((state) => state.user.userId);

  const [data, setData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [message, setMessage] = useState(""); // 상태 초기화
  const [newMessage, setNewMessage] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    // API 호출로 데이터 가져오기
    const fetchData = async () => {
      try {
        const response = await getLockDetail(mainLockId);
        setData(response.data);
        setMessage(response.data.message);
        setNewMessage(response.data.message);
        console.log(data);
      } catch (error) {
        console.error("Error fetching lock detail:", error);
      }
    };

    if (mainLockId) {
      fetchData();
    }
  }, [mainLockId]);

  const handleModalToggle = () => setIsModalOpen(!isModalOpen);

  const handleEdit = () => {
    setIsEditModalOpen(true);
    setIsModalOpen(false);
  };

  const handleDelete = async () => {
    try {
      await deleteLock(mainLockId);
      navigate("/attraction-main", { state: { shouldRefetch: true } });
    } catch (error) {
      console.error("Error deleting lock:", error);
    }
  };

  const handleEditSubmit = async () => {
    try {
      await updateLock({
        mainLockId,
        message: newMessage,
      });
      setMessage(newMessage); // 수정된 메시지로 상태 업데이트
      setIsEditModalOpen(false);
    } catch (error) {
      console.error("Error updating lock:", error);
    }
  };

  const handleLockClick = () => {
    dispatch(setLockId(mainLockId));
    console.log(mainLockId);
    navigate("/create-sub-lock");
  };

  return (
    <>
      <MainContainer>
        <ContentWrapper>
          {!data ? (
            <p className="text-center mt-20">데이터를 불러오는 중...</p>
          ) : (
            <>
              <header className="flex justify-center gap-6 items-center mt-10">
                <IoIosArrowBack
                  className="text-3xl cursor-pointer"
                  onClick={() => navigate(-1)}
                />
                <h2 className="font-bold text-xl mx-10">{data.ownerName}의 자물쇠</h2>
                <div className="relative text-center">
                  {userId === data.ownerId && (
                    <FiMoreVertical
                      className="text-2xl cursor-pointer"
                      onClick={handleModalToggle}
                    />
                  )}
                  {isModalOpen && (
                    <menu className="absolute right-0 mt-2 bg-white border border-gray-300 rounded-lg shadow-lg z-10 w-20">
                      <button
                        onClick={handleEdit}
                        className="block w-full px-4 py-3 text-center font-medium text-gray-800 bg-white hover:bg-gray-100 rounded-t-lg border-b border-gray-200 transition-all duration-300 ease-in-out"
                      >
                        수정
                      </button>
                      <button
                        onClick={handleDelete}
                        className="block w-full px-4 py-3 text-center font-medium text-red-500 bg-white hover:bg-red-50 rounded-b-lg transition-all duration-300 ease-in-out"
                      >
                        삭제
                      </button>
                    </menu>
                  )}
                </div>
              </header>
            </>
          )}

          {data && (
            <article className="flex flex-col justify-center w-full">
              <img
                src={require(`assets/${data.mainLockImage}`)}
                alt="메인 자물쇠"
                className="w-full mt-5"
                style={{ maxHeight: "200px", objectFit: "contain" }}
              />
              <p className="text-center text-2xl font-bold mx-10">{message}</p>
              {userId === data.ownerId && (
                <button
                  onClick={() =>
                    navigate("/sub-lock-list", {
                      state: { subLocks: data.subLocks },
                    })
                  }
                >
                  <span>메시지 한번에 보기</span>
                </button>
              )}
              <LockDetailSlider subLocks={data.subLocks || []} />
              <div className="flex justify-center mt-5">
                <MainLockDetailBottomTab onLockClick={handleLockClick} />
              </div>
            </article>
          )}
        </ContentWrapper>
      </MainContainer>

      {isEditModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <form
            className="bg-white rounded-lg shadow-lg p-6 w-80"
            onSubmit={(e) => e.preventDefault()}
          >
            <h2 className="text-xl font-bold mb-4 text-black text-center">
              자물쇠 내용 수정
            </h2>
            <input
              type="text"
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              placeholder={message}
              className="w-full border border-gray-300 rounded-lg p-3 mb-4 text-stone-600"
            />
            <div className="flex justify-end space-x-3">
              <button
                onClick={() => setIsEditModalOpen(false)}
                className="px-4 py-2 bg-gray-200 text-gray-800 rounded-lg"
              >
                취소
              </button>
              <button
                onClick={handleEditSubmit}
                className="px-4 py-2 bg-blue-500 text-white rounded-lg"
              >
                완료
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
}
