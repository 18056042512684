const AtrractionCard = ({ src, isSelected, onClick }) => {
  return (
    <div
      onClick={onClick} // 부모 컴포넌트에서 전달된 클릭 핸들러
      className={`
        flex justify-center items-center 
        rounded-xl
        cursor-pointer
        
      `}
    >
      <img
        className={`w-[140px]
        sm:w-[160px] md:w-[250px] lg:w-[300px]
        sm:h-[160px] md:h-[250px] lg:h-[300px]
        bg-slate-300
        rounded-xl aspect-square
        ${
          isSelected ? "border-4 border-blue-500" : "border border-transparent"
        } 
        `}
        src={src}
        alt="Attraction"
      />
    </div>
  );
};

export default AtrractionCard;
