import { useNavigate } from "react-router-dom";
import { MainContainer, ContentWrapper } from "../BackGround.styles";
import InfoUpdateForm from "./InfoUpdateForm";
import { IoIosArrowBack } from "react-icons/io";

export default function InfoUpdatePage() {
  const navigate = useNavigate()
  return (
    <MainContainer>
      <ContentWrapper>
        <div className="flex w-full flex-col mt-8">
          <div className="relative w-full max-w-[400px] flex items-center mb-2">
          <IoIosArrowBack
            className="absolute left-5 text-3xl cursor-pointer"
            onClick={() => navigate(-1)}
          />
          <h1 className="w-full text-center font-bold text-2xl">우리의 추억 자물쇠</h1>
        </div>
          <p className="text-center mt-4">Lock Our Memory</p>
        </div>
        <InfoUpdateForm />
      </ContentWrapper>
    </MainContainer>
  );
}
