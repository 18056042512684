const AttractionButton = ({ className, children, onClick }) => {
  return (
    <button
      className={`bg-white text-black 
      py-4 my-2 h-[50px] px-20
      rounded-xl ${className}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default AttractionButton;
