import LomHeader from "components/LomHeader";
import { ContentWrapper, MainContainer } from "../BackGround.styles";
import SignInForm from "./SignInForm";

const SignInPage = () => {
  return (
    <MainContainer>
      <ContentWrapper>
        <LomHeader />
        <SignInForm />
      </ContentWrapper>
    </MainContainer>
  );
};

export default SignInPage;
