import { useEffect, useState } from "react";
import { FaCheck } from "react-icons/fa";

const EmailInput = ({ value, handleEmailChange }) => {
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isEmailLocked, setIsEmailLocked] = useState(false);
  const [showCodeInput, setShowCodeInput] = useState(false);
  const [isCodeVerified, setIsCodeVerified] = useState(false);


  // 이메일 형식 유효성 검사
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValidEmail(emailRegex.test(email));
  };

  useEffect(() => {
    validateEmail(value);
  }, [value]);

  // 인증번호 버튼 클릭 핸들러
  const handleSendCodeClick = () => {
    if (isValidEmail) {
      setIsEmailLocked(true);
      setShowCodeInput(true);
    }
  };

  // 확인 버튼 클릭 핸들러
  const handleVerifyCodeClick = () => {
    setIsCodeVerified(true);
    setShowCodeInput(false);
  };

  // 취소 버튼 클릭 핸들러
  const handleCancelClick = () => {
    setIsEmailLocked(false);
    setShowCodeInput(false);
    setIsCodeVerified(false);
  };

  return (
    <div className="flex flex-col">
      <label className="font-bold ml-3">아이디(이메일)</label>
      <div className="flex items-center">
        <input
          className="bg-white/0.9 text-black rounded-xl py-3 font-bold my-2 opacity-75 pl-4 pr-10 flex-grow"
          type="email"
          placeholder="* 이메일 주소를 입력하세요."
          value={value}
          onChange={handleEmailChange}
          disabled={isEmailLocked}
        />
        {isCodeVerified && (
          <FaCheck className="text-green-500" />
        )}
        <button
          onClick={isCodeVerified ? handleCancelClick : handleSendCodeClick}
          className="ml-2 bg-blue-500 text-white px-5 py-3 rounded-xl"
        >
          {isCodeVerified ? "취소" : "인증"}
        </button>
      </div>
      {value && !isValidEmail && (
        <p className="self-end text-xs text-[#FF8D8D]">
          {"이메일을 확인해주세요"}
        </p>
      )}
      {showCodeInput && (
        <div
          className="flex items-center transition-all duration-300 ease-in-out"
          style={{
            opacity: showCodeInput ? 1 : 0,
            height: showCodeInput ? "auto" : 0,
          }}
        >
          <input
            type="text"
            placeholder="인증번호 입력"
            className="bg-white/0.9 my-2 text-black rounded-xl py-3 pl-4 pr-10 flex-grow"
          />
          <button
            onClick={handleVerifyCodeClick}
            className="ml-2 bg-green-500 text-white px-5 py-3 rounded-xl"
          >
            확인
          </button>
        </div>
      )}
    </div>
  );
};

export default EmailInput;
