import { useState } from "react";
import SignUpButton from "./SignUpButton";
import SignUpInput from "./SignUpInput";
import ReusableModal from "../../components/ReusableModal";
import checkIcon from "../../assets/check.png";
import "./SignUpStyle.css";
import { signUp } from "../../apis/userApi";
import EmailInput from "./EmailInput";
import PasswordInput from "./PasswordInput";
import { useNavigate } from "react-router-dom";

const SignUpForm = () => {
  const [email, setEmail] = useState("");
  const [nickname, setNickname] = useState("");
  const [password, setPassword] = useState("");
  const [passwordCheck, setPasswordCheck] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false); // 체크박스 상태 관리
  const [isAgreed, setIsAgreed] = useState(false); // 동의 여부 상태
  const navigate = useNavigate();

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);

    navigate("/site-sign-in");
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handleNicknameChange = (e) => {
    setNickname(e.target.value);
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const handlePasswordCheckChange = (e) => {
    setPasswordCheck(e.target.value);
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    setIsAgreed(isChecked);
  };

  const handleSignUp = async () => {
    if (!isChecked) {
      // 체크박스를 클릭하지 않았다면
      setIsAgreed(true);
      return;
    }

    setIsAgreed(false); // 동의한 경우, 경고 메시지 제거

    console.log(email, nickname, password);
    const response = await signUp({
      email: email,
      nickname: nickname,
      password: password,
    });
    console.log(response);
    if (response.status === 201) {
      openModal();
    }
  };

  return (
    <form
      className="flex flex-col w-full h-full max-w-[500px] py-10"
      onSubmit={(e) => e.preventDefault()}
    >
      <EmailInput value={email} handleEmailChange={handleEmailChange} />

      <label className="font-bold ml-3">닉네임</label>
      <SignUpInput
        placeholder="* 닉네임을 입력하세요."
        value={nickname}
        type="text"
        onChange={handleNicknameChange}
      />
      <PasswordInput
        value={password}
        handlePasswordChange={handlePasswordChange}
      />

      <label className="font-bold ml-3">비밀번호 확인</label>
      <SignUpInput
        placeholder="* 비밀번호를 재입력하세요."
        value={passwordCheck}
        type="password"
        onChange={handlePasswordCheckChange}
      />
      <p className="self-end text-xs text-[#FF8D8D]">
        {password !== passwordCheck ? "비밀번호가 일치하지 않습니다" : ""}
      </p>
      <div className="flex flex-row relative justify-center pt-7 pb-2 items-center">
        <label
          className={`custom-checkbox mt-1 ${isAgreed ? "border-red-500" : ""}`}
        >
          <input
            type="checkbox"
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
          <span className="checkmark"></span>
        </label>
        <p className="text-xs ml-2">
          이용약관 개인정보 수집 및 정보 이용에 동의합니다.
        </p>
        {isAgreed && (
          <p className="text-xs text-[#FF8D8D] absolute top-7 text-center">
            이용약관에 동의해주세요.
          </p> // 체크박스를 클릭하지 않았을 때 경고 메시지
        )}
      </div>
      <SignUpButton onClick={() => handleSignUp()}>
        <p>가입하기</p>
      </SignUpButton>

      {isModalOpen && (
        <ReusableModal
          iconSrc={checkIcon}
          title="회원가입이 완료되었습니다."
          message="로그인 후 이용해주세요."
          buttonText="로그인 화면으로 돌아가기"
          onClose={closeModal}
        />
      )}
    </form>
  );
};

export default SignUpForm;
