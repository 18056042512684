import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mainLockId: 0,
  message: "",
  location: 0,
  design: 0,
};

export const subLockSlice = createSlice({
  name: "subLock",
  initialState,
  reducers: {
    setLockId: (state, action) => {
      state.mainLockId = action.payload;
    },
    setLocation: (state, action) => {
      state.location = action.payload;
    },
    setdesign: (state, action) => {
      state.design = action.payload;
    },
    setMessage: (state, action) => {
      state.message = action.payload;
    },
  },
});

export const { setLockId, setLocation, setdesign, setMessage } = subLockSlice.actions;
export default subLockSlice.reducer;
