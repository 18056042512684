import { createSlice } from "@reduxjs/toolkit";
import namsan from "assets/namsan.jpg"; // 초기 배경 이미지

const initialState = {
  backgroundImage: namsan, // 초기 배경 이미지 설정
  backgroundName: "남산타워"
};

export const backgroundSlice = createSlice({
  name: "background",
  initialState,
  reducers: {
    setBackgroundImage: (state, action) => {
      state.backgroundImage = action.payload; // 배경 이미지를 업데이트
    },
    setBackgroundName: (state, action) => {
      state.backgroundName = action.payload; // 배경 이미지를 업데이트
    },
  },
});

export const { setBackgroundImage, setBackgroundName } = backgroundSlice.actions;
export default backgroundSlice.reducer;
