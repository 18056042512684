import { useNavigate } from "react-router-dom";
import AttractionButton from "../AttractionPage/AttractionButton";
import { ContentWrapper, MainContainer } from "../BackGround.styles";
import { IoIosArrowBack } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { setMessage } from "stores/subLockSlice";
import { createSubLock } from "apis/lockApi";

const LockMessagePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const mainLockId = useSelector((state) => state.subLock.mainLockId);
  const design = useSelector((state) => state.subLock.design);
  const [lockmessage, setLockMessage] = useState("");
  const [isVisible, setIsVisible] = useState(true);
  const maxLength = 50;

  const toggleSecret = () => {
    setIsVisible((prev) => !prev);
    console.log(`비밀글 설정: ${!isVisible ? "활성화" : "비활성화"}`);
  };

  const handleInputChange = (e) => {
    const input = e.target.value;

    // 한글 글자 수 계산
    const length = input.length;

    // 50자를 초과하면 기존 값 유지
    if (length <= maxLength) {
      setLockMessage(input);
    }
  };
  const handleButtonClick = async () => {
    console.log(mainLockId);
    dispatch(setMessage(lockmessage));
    // console.log(hotPlaceId, design, lockmessage)
    const response = await createSubLock({
      mainLockId,
      subLockImageId: design,
      message: lockmessage,
      visible: isVisible,
    });
    navigate(`/main-lock-detail?mainLockId=${mainLockId}`);
  };
  return (
    <MainContainer>
      <ContentWrapper className="flex flex-col items-center">
        <div className="relative w-full max-w-[400px] flex items-center mt-12 mb-4">
          <IoIosArrowBack
            className="absolute left-0 text-3xl cursor-pointer"
            onClick={() => navigate(-1)}
          />
          <h1 className="w-full text-center font-bold text-2xl">
            자물쇠 메시지 작성
          </h1>
        </div>
        <div className="w-full">
          <div className="flex items-center gap-3 mb-3">
            <button
              onClick={toggleSecret}
              className={`relative w-12 h-6 rounded-full transition-colors ${
                isVisible ? "bg-green-500" : "bg-gray-300"
              }`}
            >
              <span
                className={`absolute w-5 h-5 bottom-[2px] bg-white rounded-full transition-transform transform 
                  ${isVisible ? "translate-x-[2px]" : "-translate-x-[22px]"}`}
              ></span>
            </button>
            <span className="text-sm">
              {isVisible ? "모두에게 공개" : "비공개"}
            </span>
          </div>
          <textarea
            value={lockmessage}
            onChange={handleInputChange}
            className="text-black
          w-full rounded-xl p-4
          resize-none"
            placeholder={`최대 ${maxLength}자 입력 가능합니다.`}
          />
          <div className="flex justify-end">
            <span
              className={`bg-white/60 px-1 rounded-sm text-sm mt-1 ${
                lockmessage.length === maxLength
                  ? "text-red-500"
                  : "text-gray-500"
              }`}
            >
              {lockmessage.length}/{maxLength}자
            </span>
          </div>
        </div>
        <AttractionButton className="mb-4" onClick={handleButtonClick}>
          완료하기
        </AttractionButton>
      </ContentWrapper>
    </MainContainer>
  );
};

export default LockMessagePage;
